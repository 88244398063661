import {
  Button,
  Coaching,
  Heading,
  InclusionSurvey,
  Individual360,
  Informative,
  OneOnOne,
  ReadArticle,
  RemoteManager,
  Team1,
  TeamEffectiveness1,
  Video,
} from "@kaizen/components"
import { Card, Text } from "@kaizen/components"
import { Tile as TileInterface } from "src/tiles/ExploreTiles/api/data"
import { ampli } from "src/ampli"
import { useIntl } from "@cultureamp/i18n-react-intl"
import { useTileNameTrackerContext } from "../../../../components/TileDisplayedTracker/TileDisplayedTracker"
import { useTrackOnView } from "@cultureamp/analytics"
import React, { PropsWithChildren, useEffect, useMemo } from "react"

const ExploreTileIllustration = {
  Coaching,
  InclusionSurvey,
  Individual360,
  Informative,
  OneOnOne,
  ReadArticle,
  RemoteManager,
  Team1,
  TeamEffectiveness1,
  Video,
}

type ExploreTileIllustrationType = {
  Coaching: typeof Coaching
  InclusionSurvey: typeof InclusionSurvey
  Individual360: typeof Individual360
  Informative: typeof Informative
  OneOnOne: typeof OneOnOne
  ReadArticle: typeof ReadArticle
  RemoteManager: typeof RemoteManager
  Team1: typeof Team1
  TeamEffectiveness1: typeof TeamEffectiveness1
  Video: typeof Video
}

type TileProps = {
  id:
    | "directory"
    | "directReports"
    | "teams"
    | "support"
    | "oneOnOne"
    | "skillsCoach"
    | "blog"
    | "community"
    | "training"
    | "threeSixty"
  title: string
  description: string
  primaryAction: {
    label: string
    href?: string
    onClick?: () => void
  }
  illustration: {
    image: keyof ExploreTileIllustrationType
  }
  isInFeed?: boolean
}

const Tile = (tile: PropsWithChildren<TileProps>) => {
  const { title, label, description, ariaLabel } = useTileTranslations(tile.id)
  const { recordTilesDisplayed } = useTileNameTrackerContext()
  useTrackOnView(() => recordTilesDisplayed([tile.id]))
  const Illustration = ExploreTileIllustration[tile.illustration.image]

  const tileButtonId = useMemo(() => `${tile.id}-button`, [tile])

  useEffect(() => {
    // Manually adding the aria-label because Kaizen button API doesn't let us do this
    const tileButtonElement = document.getElementById(tileButtonId)

    if (
      tileButtonElement &&
      !tileButtonElement.hasAttribute("aria-label") &&
      ariaLabel
    ) {
      tileButtonElement.setAttribute("aria-label", ariaLabel)
    }
  }, [tileButtonId, ariaLabel])

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()

    tile.isInFeed
      ? ampli.homepageTileClicked({
          "Tile name": `${tile.id}: ${tile.title}`,
          "Tile type": "secondary",
          "Feed section": undefined,
        })
      : ampli.unifiedHomeTileClicked({
          "Tile name": tile.title,
          "Tile type": "secondary",
        })

    if (tile.primaryAction.onClick) {
      tile.primaryAction.onClick()
    }

    if (tile.primaryAction.href) {
      window.location.href = tile.primaryAction.href
    }
  }

  return (
    <Card classNameOverride="box-border h-full px-24 py-32 shadow-none">
      <div className="h-full flex flex-col items-center gap-24 text-center">
        <div className="h-[156px] w-[156px]">
          <Illustration />
        </div>
        <Heading variant="heading-3">{title}</Heading>
        <Text variant="small">{description}</Text>
        <div className="mt-auto">
          <Button id={tileButtonId} label={label} onClick={handleOnClick} />
        </div>
        {tile.children}
      </div>
    </Card>
  )
}

const useTileTranslations = (
  id: TileInterface["id"],
): {
  title: string
  description: string
  label: string
  ariaLabel?: string
} => {
  const { formatMessage } = useIntl()

  switch (id) {
    case "blog":
      return {
        title: formatMessage({
          id: "tiles.blog.title",
          defaultMessage: "Culture Amp blog",
          description:
            "Culture Amp blog title text. 'Culture Amp' is a company name and should not be translated",
        }),
        description: formatMessage({
          id: "tiles.blog.description",
          defaultMessage:
            "Articles about company culture and employee experience",
          description: "Culture Amp blog descriptive text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.blog.aria",
          defaultMessage: "Explore the Culture Amp blog",
          description: "Accessibility label for Call-to-action button",
        }),
        label: formatMessage({
          id: "tiles.blog.primaryAction.label",
          defaultMessage: "Explore the blog",
          description: "Call-to-action button label text",
        }),
      }
    case "community":
      return {
        title: formatMessage({
          id: "tiles.community.title",
          defaultMessage: "Culture First Community",
          description: "Title text",
        }),
        description: formatMessage({
          id: "tiles.community.description",
          defaultMessage:
            "Our online community is a great place to ask questions",
          description: "Online community description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.community.primaryAction.aria",
          defaultMessage: "Join the Culture First Community",
          description: "Accessibility label for Call-to-action button",
        }),
        label: formatMessage({
          id: "tiles.community.primaryAction.label",
          defaultMessage: "Join the community",
          description: "Call-to-action button label text",
        }),
      }
    case "directReports":
      return {
        title: formatMessage({
          id: "tiles.directReports.title",
          defaultMessage: "Direct reports",
          description:
            "Direct reports title text. In this context 'direct reports' refers to subordinate employees who report to the current user.",
        }),
        description: formatMessage({
          id: "tiles.directReports.description",
          defaultMessage:
            "View information about the employees who report directly to you",
          description: "Direct reports description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.directReports.primaryAction.aria",
          defaultMessage: "View your direct reports",
          description:
            "Call-to-action button label text. In this context 'direct reports' refers to subordinate employees who report to the current user.",
        }),
        label: formatMessage({
          id: "tiles.directReports.primaryAction.label",
          defaultMessage: "View direct reports",
          description:
            "Call-to-action button label text. In this context 'direct reports' refers to subordinate employees who report to the current user.",
        }),
      }
    case "directory":
      return {
        title: formatMessage({
          id: "tiles.directory.title",
          defaultMessage: "Find a colleague",
          description: "Directory title text",
        }),
        description: formatMessage({
          id: "tiles.directory.description",
          defaultMessage:
            "Quickly give and request feedback for any employee in your company",
          description: "Directory description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.directory.primaryAction.aria",
          defaultMessage: "Search the employee directory",
          description: "Accessibility label for Call-to-action button",
        }),
        label: formatMessage({
          id: "tiles.directory.primaryAction.label",
          defaultMessage: "Search directory",
          description: "Call-to-action button label text",
        }),
      }
    case "oneOnOne":
      return {
        title: formatMessage({
          id: "tiles.oneOnOne.title",
          defaultMessage: "Start a conversation",
          description: "Title text",
        }),
        description: formatMessage({
          id: "tiles.oneOnOne.description",
          defaultMessage: "Take your 1-on-1s beyond simple status updates",
          description:
            "1-on-1s description text. A 1-on-1 is a semi-formal, catch-up meeting between a manager and one of their employees",
        }),
        ariaLabel: formatMessage({
          id: "tiles.oneOnOne.primaryAction.aria",
          defaultMessage: "View your 1-on-1s",
          description:
            "Call-to-action button label text. A 1-on-1 is a semi-formal catch-up meeting between a manager and one of their employees",
        }),
        label: formatMessage({
          id: "tiles.oneOnOne.primaryAction.label",
          defaultMessage: "View 1-on-1s",
          description:
            "Call-to-action button label text. A 1-on-1 is a semi-formal catch-up meeting between a manager and one of their employees",
        }),
      }
    case "skillsCoach":
      return {
        title: formatMessage({
          id: "tiles.skillsCoach.title",
          defaultMessage: "Skills coach",
          description: "Title text",
        }),
        description: formatMessage({
          id: "tiles.skillsCoach.description",
          defaultMessage:
            "Using behavioral science and spaced repetition to drive real change",
          description: "Skills coach description text",
        }),
        label: formatMessage({
          id: "tiles.skillsCoach.primaryAction.label",
          defaultMessage: "View skills coach",
          description: "Call-to-action button label text",
        }),
      }
    case "support":
      return {
        title: formatMessage({
          id: "tiles.support.title",
          defaultMessage: "Search support guides",
          description: "Title text",
        }),
        description: formatMessage({
          id: "tiles.support.description",
          defaultMessage:
            "Access tips and tricks about how to use the Culture Amp platform",
          description: "Support description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.support.primaryAction.aria",
          defaultMessage: "Search the Culture Amp support guide",
          description: "Support description text",
        }),
        label: formatMessage({
          id: "tiles.support.primaryAction.label",
          defaultMessage: "Search guides",
          description:
            "Call-to-action button label text. Refers to searching support guides for help using the platform",
        }),
      }
    case "teams":
      return {
        title: formatMessage({
          id: "tiles.teams.title",
          defaultMessage: "Explore teams",
          description:
            "Title text. 'Explore teams' meaning to search through the list of teams to which you belong on the platform",
        }),
        description: formatMessage({
          id: "tiles.teams.description",
          defaultMessage:
            "View cross-functional teams you belong to and team goals",
          description: "Description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.teams.primaryAction.aria",
          defaultMessage: "Search the teams directory",
          description:
            "Call-to-action button label text. Invites the user to search the teams directory",
        }),
        label: formatMessage({
          id: "tiles.teams.primaryAction.label",
          defaultMessage: "Search teams",
          description:
            "Call-to-action button label text. Invites the user to search the teams directory",
        }),
      }
    case "threeSixty":
      return {
        title: formatMessage({
          id: "tiles.threeSixty.title",
          defaultMessage: "Drive your development",
          description:
            "Title text. In this context 'development' means career/workplace skills development",
        }),
        description: formatMessage({
          id: "tiles.threeSixty.description",
          defaultMessage:
            "Gather feedback and insights to fuel your personal development",
          description: "Description text",
        }),
        ariaLabel: formatMessage({
          id: "tiles.threeSixty.primaryAction.aria",
          defaultMessage: "Launch a 360 survey",
          description:
            "Call-to-action button label text. A 360 is a kind of formal workplace review process (ie, 360 degree view)",
        }),
        label: formatMessage({
          id: "tiles.threeSixty.primaryAction.label",
          defaultMessage: "Launch 360",
          description:
            "Call-to-action button label text. A 360 is a kind of formal workplace review process (ie, 360 degree view)",
        }),
      }
    case "training":
      return {
        title: formatMessage({
          id: "tiles.training.title",
          defaultMessage: "On-demand training",
          description: "Title text",
        }),
        description: formatMessage({
          id: "tiles.training.description",
          defaultMessage:
            "Get the most from Culture Amp with on-demand and live training",
          description:
            "Description text. 'Culture Amp' is a brand name and should not be t,ranslated",
        }),
        ariaLabel: formatMessage({
          id: "tiles.training.primaryAction.aria",
          defaultMessage: "Master our platform with training modules",
          description:
            "Call-to-action button label text. Invites the user to use the training modules to 'master' (ie, become expert at) the software platform they're using",
        }),
        label: formatMessage({
          id: "tiles.training.primaryAction.label",
          defaultMessage: "Master our platform",
          description:
            "Call-to-action button label text. Invites the user to use the training modules to 'master' (ie, become expert at) the software platform they're using",
        }),
      }
  }
}

export default Tile
